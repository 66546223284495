/*
 * Custom CSS
 */
 body {
  background-color: #06357A;
}
.modal-open {
  overflow: hidden;
}
.container-custom-color {
  background-color: rgb(255, 255, 255); 
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 10px;
}

:root {
  --bs-body-bg: var(--bs-gray-100);
}

.navbar-custom-icd {
  background-color: #0059A7;
  color: rgba(34, 34, 34, 0.8);
  border-top: solid #06357A 10px;
}

.nav-link {
  color: #ffffff !important;
}

.navbar-nav.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.navbar-nav .nav-item .dropdown-menu {
  background-color: rgb(0, 104, 108);
}

.navbar-nav .nav-item1 {
  background-color: #5d9631;/* Adjust as needed */
}

.navbar-nav .nav-item .dropdown-item {
  color: #ffffff;
}

.navbar-nav .nav-item .dropdown-item:hover {
  background-color: #0059A7;
}

.nav-background-color {
  background-color: #5d9631;
}

.mr-50-custom {
  margin-right: 50px;
}

.heading {
  font-weight: bold;
  font-size: 1.1rem;
  color: #0c696c;
}

.heading-box {
  border: 2px solid #0c696c;
  border-radius: 6px;
  padding: 3px;
}

.account-balance {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: auto;
}

.weltext {
  color: #0059A7;
  font-size: 1.9rem;
  font-weight: bold;
}

.tblcontent {
  color: gray;
}


.strong-red{
  color: red;  
  font-weight: bolder;
  text-decoration: none;
}

.strong-red:hover{
  text-decoration: underline;
}
.stepTab {
  border-radius: 20px;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  /* justify-content: center; */
  /* margin: 10px auto; */
  margin-bottom: 1rem;
  width: 100%;
}

.stepTab li.completed {
  background-color: rgb(93, 150, 49);
  color: #fff;
}
li.completed a{
  color: #fff;
}
.stepTab li.completed button{
  background-color: rgb(93, 150, 49);
  color: #fff;
}
li.completed button{
  color: #fff;
}
.stepTab li {
  font-size: 80%;
  padding: 6px 30px;
  white-space: nowrap;
}

.stepTab li.stepfirst {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.stepTab li.steplast {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.stepTab li.activeTab{
  background-color: #0059A7;
  color: #fff;
}

.stepTab li.activeTab a{
 
  color: #fff;
}
.stepTab li.activeTab button{
  background-color: #0059A7;
  color: #fff;
}
li.activeTab button{
  color: #fff;
}
.link {
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 70%;
  padding: 6px 8px;
  margin: 0 10px;
}

#tr:nth-child(even) {
  background-color: #e6f2ff;
}

.altRow {
  background-color: #e6f2ff;
}

.eventd {
  background-color: #e6f2ff !important;
}


.heading1 {
  border: 2px solid #0c696c;
  border-radius: 6px;
  padding: 3px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #0c696c;
}

.Email-Text {
  padding: 5px;
  font-size-adjust: 0.38;
}

.reqTable TH{
  padding: 2px;
}
.reqTable TD{
  padding: 2px;
}

.app-id-text{
  font-size: 20px;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .d-flex {
    flex-direction: column !important;
  }
  
  .heading-box {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .account-balance {
    margin-left: 0 !important;
    margin-top: 10px;
  }

}
@media only screen and (min-width: 1024px) {
  /* CSS rules for screens 1024px and larger */
  .logo-right-space{
    margin-left: 60px;
  }
}


.position-relative {
  position: relative;
}

.upload-btn {
  position: absolute;
  bottom: 1px; 
  left: 1px; 
  background-color: white; 
  color: #0059A7; 
  opacity: 0.9;
  padding: 5px 10px; 
  font-size: 0.9rem; 
  display: flex; 
  align-items: center; 
  gap: 5px; 
}

.upload-btn:hover {
  opacity: 1;
  background-color: #f0f0f0; 
}

.upload-btn i {
  font-size: 1rem; /* Size of the icon */
  color: #0059A7
}
.footer-bottom{
  margin-top: 0rem;
  margin-bottom: 0rem;
  height: 66px
}

.privacy-text {
   color :#0059A7;
   margin-bottom: 0px;
}

.btn-link-edit {
  padding-top: 0px;
  color: #0d6efd;
  border-color: #ffffff;
}
.btn-link-edit:hover {
  border-color: #ffffff;
}

/* Base style for the icon */
.pencil-icon {
fill: #0d6efd;
transition: fill 0.3s ease;
}

/* Style for hover and focus states */
.pencil-icon:hover,
.pencil-icon:focus {
color: #0b5ed7; /* Slightly darker shade for hover effect */
}

/* Order history */
 /* Custom CSS */
 body .orderhistory {
  background-color: #06357A;
  font-family: Arial, sans-serif;
  color: #333;
}

.list {
  display: flex;
  justify-content: center; /* Center the links */
  flex: 1;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.nav-link:hover {
  text-decoration: underline;
}
.anchor-button {
  background: none;
  color: blue;
  text-decoration: underline;
  border: none;
  padding: 0;
  cursor: pointer;
  font: inherit;
}
.user-greeting {
  margin-left: auto;
}

.heading-orderhistory {
 
  padding: 3px;
  font-weight: bold;
  font-size: 110%;
  color: #0c696c;
  margin-bottom: 5px;
  /* display: inline-block; */
  text-align: center;
  
}

.weltext {
  color: #0059A7;
  /*font-size: 190%;*/
  font-weight: bold;
  margin-bottom: 20px;
}
.container-custom-color-history-ICD {
background-color: rgb(255, 255, 255); 
padding: 10px;
border-radius: 10px;
margin-top: 10px;
margin-left: 70px;
margin-right: 70px;
margin-bottom: 10px;
}
.table-container-history {
  max-height: 400px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

table-history {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}


.div-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.historydetails {
  text-align: center;
}
.tablehistoryde{
  border: 1px solid #ccc;
  text-align: left;
  padding: 8px;
  font-size:14px;
}

.table-order-history{
width:100%;
border: 1px solid rgb(207, 204, 204);
}
.table-order-history th{
height: 40px;
background-color: none !important;
border: 1px solid rgb(207, 204, 204) !important;
padding-left: 0.5rem;
}
.table-order-history td{
height: 40px;
/* background-color: none !important; */
border-bottom: 1px solid rgb(236, 235, 235) !important;
padding-left: 0.5rem;
text-align: left;
}
.table-order-history td:nth-child(3) {
text-align: right;
padding-right: 0.5rem;
}
.language-button {
padding-top: 0px;
background: #ffffff;
border: none;
padding-left: 0px;
color:#06357A;
font-weight: 500;
}

.bordered-round-btn {
  border-width: 1px;
  border-radius: 15px;  
  background-color: white; 
  color: #0059A7; 
  padding: 1px 5px;  
}

.table tr:nth-of-type(odd){
  background-color: hsla(210, 67%, 99%, 0.763);
}

.table tr:nth-of-type(even){  
  background-color: hsla(211, 100%, 95%, 0.63);
}
.translinks a {  
  background-color: hsla(210, 100%, 95%, 0);
}

.translinks a {  
  background-color: hsla(220, 60%, 99%, 0);
}

.profile-details img{
  width: 160px;
  height: auto;  
}
@media (max-width: 768px) {
  .icon-success {
    font-size: 2px; /* Adjust size as needed */
    color: green;    /* Adjust color as needed */
  }
    
  .container-custom-color {
    margin: 0px;
    padding-top: 0px;
    padding-left: 2px;    
    padding-right: 2px;
  }
  .title h3{
    font-size:20px;
  }
  .language-button{
    
    margin-top: 0px;
    padding-top: 0px;
    font-size:12px;
  }
  
  .privacy-text{
    font-size: small;
  }
  .rights{
    font-size: smaller;
  }  
}


@media (max-width: 576px) {
  .weltext {
    font-size: 1.5rem;
  }

  .stepTab {
    flex-direction: column;
    align-items: center;
  }

  .stepTab li {
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  .Email-Text {
    padding: 0;
  }
  .privacy-text{
    font-size: x-small;
  }
  .rights{
    font-size: x-small;
  }
}


  /* step Tab */
  @media (max-width: 1480px) {

    .stepTab li {
      font-size: 80%;
      padding: 6px 10px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.6rem;
    }
    .profile-details img{
      width: 120px;
      height: auto;  
    }
    .profile-details button{
      font-size: xx-small;
      width: 120px;
      padding: 1px;
      background-color: #ffffffa6;
    }
  }
  @media (max-width: 1280px) {

    .stepTab li {
      font-size: 75%;
      padding: 4px 5px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.5rem;
    }
    .profile-details img{
      width: 100px;
      height: auto;  
    }
    
    .app-id-text{
      font-size: 16px;
    }
    
    .profile-details button{
      font-size: x-small;
      width: 100px;
      padding: 0px;
    }
  }
  
  @media (max-width: 1150px) {

    .stepTab li {
      font-size: 60%;
      padding: 4px 5px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.3rem;
    }
    .profile-details img{
      width: 80px;
      height: auto;  
    }
    .app-id-text{
      font-size: 14px;
    }
    .profile-details button{
      font-size: xx-small;
      width: 80px;
      padding: 0px;
    }
  }