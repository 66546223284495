.container {
    display: flex;
    justify-content: space-between;
    width:100%;
}

.paypal {
   margin: 8px 0;
}

.form {
    width: 70%;
    background-color: white;
    padding: 20px;
    margin-top: 4px; 
    margin-bottom: 4px;
    border-radius: 8px;

}

 .input{
    padding: 12px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 8px 2px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 14px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

 input {
    border: none; 
    width: 100%; 
    height: 100%; 
    float: left;
} 

td {
    padding: 3px 10px;
}

.button {
    background-color: #000;
    color: #fff;
    font-size: 18px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin: 8px 0;
}

 .button:hover {
    background-color: #222;
}
  
#form .clear {
    clear: both;
}

.messageok {

    color:green;
  
  }
  
  .messageerror {
    color:red;
  }


  /* PaymentsSite.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .page-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px); 
    padding: 20px;
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  
  /* Add custom styles for PaymentForm and the message content */
  h4 {
    color: #333;
    font-size: 18px;
    text-align: center;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /*Custom styles specifically for the 'Select a Country' dropdown */
  .input {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  @media (max-width: 767px) {
    .payment-section {
      padding: 10px;
    }
  
    .form {
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
    }
  
    .input {
      width: 100%; 
      max-width: 100%; 
    }
  
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px; 
    }
  
    .col-md-6, .col-md-12 {
      flex: 1 1 100%; 
      max-width: 100%;
      padding: 5px; 
    }
  
    .form-group {
      margin-bottom: 15px; 
    }
  
    .refund-policy {
      flex-direction: column; 
      align-items: flex-start;
    }
  
    .button-group {
      text-align: center;
    }
  }  
  