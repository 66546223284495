.notifications-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;    
  }
  
  .bell-icon-container {
    position: relative;
  }
  
  .notifications-icon {
    font-size: 20px;
    color: white;
  }
  
  .notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 1px 6px;
    font-size: 10px;
    font-weight: bold;
  }
  
  .notifications-dropdown {
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    min-width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;    
    font-family: var(--lwc-navigationFontFamily, Ek Mukta);
  }
  
  .notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    color: rgb(51,51,51);
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }
  
 .close-button {
  cursor: pointer;
  font-size: 16px;
}

  
  .notification-item {
    padding: 10px;
    display: flex;
  }
  
  .notification-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .notification-content-header{
    margin: 0;
    font-size: 13px;
    font-weight: 700;
  }
  
  .notification-content p {
    margin: 0;
    font-size: 13px;    
    font-weight: 400;
    width: 400px;
  }
  
  .notification-date-time {
    font-size: 12px;
    color: rgb(105,105,105);
    margin-top: 4px!important;
  }
  