/*
 * Custom CSS
 */
 body {
    background-color: #06357A;
  }
  .modal-open {
    overflow: hidden;
  }
.container-custom-color-cgfns{
  background-color: rgb(255, 255, 255); 
  padding: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

  :root {
    --bs-body-bg: var(--bs-gray-100);
  }
  
  .navbar-custom {
    background-color: rgb(0, 104, 108);
    color: rgba(34, 34, 34, 0.8);
    border-top: solid #06357A 10px;
  }
  
  .nav-link {
    color: #ffffff !important;
  }
  
  .navbar-nav.m-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .navbar-nav .nav-item .dropdown-menu {
    background-color: rgb(0, 104, 108);
  }
  
  .navbar-nav .nav-item1-cgfns {
    background-color: #0059A7; /* Adjust as needed */
  }
  
  .navbar-nav .nav-item .dropdown-item {
    color: #ffffff;
  }
  
  .navbar-nav .nav-item .dropdown-item:hover {
    background-color: #0059A7;
  }
  
  .nav-background-color-cgfns {
    background-color: #06357A;
  }
  .anchor-button {
    background: none;
    color: blue;
    text-decoration: underline;
    border: none;
    padding: 0;
    cursor: pointer;
    font: inherit;
  }
  .mr-50-custom {
    margin-right: 50px;
  }
  
  .heading {
    font-weight: bold;
    font-size: 1.1rem;
    color: #0c696c;
  }
  
  .heading-box {
    border: 2px solid #0c696c;
    border-radius: 6px;
    padding: 3px;
  }
  
  .account-balance {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: auto;
  }
  
  .weltext {
    color: #0059A7;
    font-size: 1.9rem;
    font-weight: bold;
  }
  
  .tblcontent {
    color: gray;
  }
  
  .stepTab-cgfns {
    border-radius: 20px;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    /* justify-content: center; */
    /* margin: 10px auto; */
    margin-bottom: 1rem;
    width: 99%;
  }
  
  .stepTab-cgfns li {
    font-size: 90%;
    padding: 6px 30px;
  }
  
  .stepTab-cgfns li.stepfirst {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .stepTab-cgfns li.steplast {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .stepTab-cgfns li.activeTab {
    background-color: #0059A7;
    color: #fff !important;
  }
  
  .activeTab a{
    color: #fff !important;
  }
  
  .stepTab li.activeTab button{
    background-color: #0059A7;
    color: #fff;
  }
  li.activeTab button{
    color: #fff;
  }
  .link {
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 70%;
    padding: 6px 8px;
    margin: 0 10px;
  }
  
  #tr:nth-child(even) {
    background-color: #e6f2ff;
  }
  
  .altRow {
    background-color: #e6f2ff;
  }
  
  .eventd {
    background-color: #e6f2ff !important;
  }
/*   
  tr td {
    height: 150px;
  }
   */
  .heading1 {
    border: 2px solid #0c696c;
    border-radius: 6px;
    padding: 3px;
    font-weight: bold;
    font-size: 1.1rem;
    color: #0c696c;
  }
  
  .Email-Text {
    padding: 5px;
    font-size-adjust: 0.38;
  }

  .app-id-text{
    font-size: 20px;
  }
  
  /* step Tab */
  @media (max-width: 767px) {
  }

  /* Responsive Styles */
  @media (max-width: 767px) {
    .d-flex {
      flex-direction: column !important;
    }
    
    .heading-box {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  
    .account-balance {
      margin-left: 0 !important;
      margin-top: 10px;
    }
    .order-row {
      flex-direction: row;
    }
  
    .order-header {
      width: 50%;
    }
  
    .order-detail {
      width: 50%;
      text-align: left;
    }
  
    /* Adjust the separator position for small screens */
    .order-row::before {
      left: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .weltext {
      font-size: 1.5rem;
    }
  
    .stepTab {
      flex-direction: column;
      align-items: center;
    }
  
    .stepTab li {
      padding: 10px;
      width: 100%;
      text-align: center;
    }
    .Email-Text {
      padding: 0;
    }
    .privacy-text{
      font-size: x-small;
    }
    .rights{
      font-size: x-small;
    }
  }
  
  .upload-btn-cgfns {
    position: absolute;
    bottom: 1px; 
    left: 1px; 
    background-color: white; 
    color: #0059A7; 
    opacity: 0.9;
    padding: 5px 10px; 
    font-size: 0.9rem; 
    display: flex; 
    align-items: center; 
    gap: 5px; 
  }
  
  .upload-btn-cgfns:hover {
    opacity: 1;
    background-color: #f0f0f0; 
  }
  
  .upload-btn-cgfns i {
    font-size: 1rem; /* Size of the icon */
    color: #0059A7
  }
  
  .footer-color {
    color: #0059A7;
  }
  
  .myOrders {
     padding: 0 5px;
  }

  .btn-link-edit-cgfns {
    padding-top: 0px;
    color: #0d6efd;
    border-color: #ffffff;
  }
  .btn-link-edit-cgfns:hover {
    border-color: #ffffff;
  }
  
.pencil-icon-cgfns {
  fill: #0d6efd;
  transition: fill 0.3s ease;
}

.pencil-icon-cgfns:hover,
.pencil-icon-cgfns:focus {
  color: #0b5ed7; 
}
/* Mobile view styles */
.order-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.order-header {
  font-weight: bold;
  margin-right: 10px;
  width: 40%;
  padding-right: 10px; /* Adjust as needed */
  box-sizing: border-box;
}

.order-detail {
  flex: 1;
  padding-left: 10px; /* Adjust as needed */
  box-sizing: border-box;
}

/* Vertical separator line */
.order-row::before {
  content: "";
  position: absolute;
  left: 40%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ddd;
}

/* Ensure the last row does not have a bottom border */
.order-row:last-child {
  border-bottom: none;
}
  
  /* Order history */
  /* Custom CSS */
  body .orderhistory {
    background-color: #06357A;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  
  .navbar-custom-cgfns {
    background-color: #00686C;
    color: rgba(34, 34, 34, 0.8);
    border-top: solid #06357A 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .list {
    display: flex;
    justify-content: center; /* Center the links */
    flex: 1;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
  .user-greeting {
    margin-left: auto;
  }
  
  .heading-orderhistory {
   
    padding: 3px;
    font-weight: bold;
    font-size: 110%;
    color: #0c696c;
    margin-bottom: 5px;
    /* display: inline-block; */
    text-align: center;
    
  }
  
  .weltext {
    color: #0059A7;
    font-size: 190%;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .container-custom-color-history {
    background-color: rgb(255, 255, 255); 
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  }
  .table-container-history {
    max-height: 400px; /* Set the max height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  table-history {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  /* table, th, td { 
    border: 1px solid #ccc;
    text-align: left;
    padding: 8px;
    font-size:14px;
  } */
  
  /* th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  tr:nth-child(even) {
    background-color: #e6f2ff;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  } */
  
  /* tr td {
    height: 150px;
  } */
  
  .div-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .historydetails {
    text-align: center;
  }
  .tablehistoryde{
    border: 1px solid #ccc;
    text-align: left;
    padding: 8px;
    font-size:14px;
  }
  
.table-order-history{
  width:100%;
  border: 1px solid rgb(207, 204, 204);
}
.table-order-history th{
  height: 40px;
  background-color: none !important;
  border: 1px solid rgb(207, 204, 204) !important;
  padding-left: 0.5rem;
}
.table-order-history td{
  height: 40px;
 /* background-color: none !important; */
  border-bottom: 1px solid rgb(236, 235, 235) !important;
  padding-left: 0.5rem;  
  text-align: left;
}
.table-order-history td:nth-child(3) {
text-align: right;
padding-right: 0.5rem;
}
.language-button {
  padding-top: 0px;
  background: #ffffff;
  border: none;
  padding-left: 0px;
  color:#06357A;
  font-weight: 500;
}

  /* StatusTracker */

  /* src/styles.css */

  body {
    background-color: #06357A;
  }
  
  .container-custom-color {
    background-color: rgb(255, 255, 255);
  }
  
  .container-custom-padding {
    padding: 40px;
    margin-top: 10px !important;
  }
  
  
  
  :root {
    --bs-body-bg: var(--bs-gray-100);
  }
  
  .navbar-custom {
    background-color: rgb(0, 104, 108);
    color: rgb(34, 34, 34, .8);
    border-top: solid #06357A 10px;
  }
  
  .nav-link {
    color: #ffffff !important;
  }
  
  .nav-background-color {
    background-color: #06357A;
  }
  
  .mr-50-custom {
    margin-right: 50px;
  }
  
  .logo-custom {
    height: 80px;
    margin-right: 50px;
  }
  
  .icon-color {
    color: #ffffff;
  }
  
  
  
  .weltext {
    color: #0059A7;
    font-size: 190%;
    font-weight: bold;
  }
  
  .tblcontent {
    color: gray;
  }
  
  .stepTab-cgfns {
    border-radius: 20px;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    margin: 10px auto;
  }

  .stepTab-cgfns a{
    text-decoration: none;
  }
  
  .stepTab-cgfns li {
    float: left;
    font-size: 90%;
    padding: 6px 30px;
  }
  
  .stepTab-cgfns li.stepfirst {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .stepTab-cgfns li.steplast {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .stepTab-cgfns li.activeTab a{
    background-color: #0059A7;
    color: #fff;
    text-decoration: none;
  }
  
  .stepTab-cgfns li.activeTab button{
    background-color: #0059A7;
    color: #fff;
    text-decoration: none;
  }
  .stepTab-cgfns li.completed {
    background-color: #00686C;
    color: #fff;
  }
  li.completed a{
    color: #fff;
  }
 
.stepTab li.completed button{
  background-color: #00686C;
  color: #fff;
}
li.completed button{
  color: #fff;
}
  .link {
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 70%;
    padding: 6px 8px;
    margin: 0 10px;
  }
  
  #tr:nth-child(even) {
    background-color: #e6f2ff;
  }
  
  .altRow {
    background-color: #e6f2ff;
  }
  
  .eventd {
    background-color: #e6f2ff !important;
  }
  
  
  
  .link-button {
    background: none;
    color: blue;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .link-button:hover {
    color: darkblue;
  }
  
  
  .small-font {
    font-size: 14px;
  }
  
  .separator {
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }
  .small-table {

    font-size: 12px;
    padding: 5px;
    margin: auto;
  }
  
  .small-table th,
  .small-table td {
    padding: 8px;
  }
  
  /*akhl changes */
  .custom-font {
    font-family: var(--lwc-navigationFontFamily, Ek Mukta);
    font-size: 14px;
  }
  .help-text {
    font-size: 0.875em;
    color: #6c757d;
    margin-left: 5px;
  }
  
  
  .custom-margin {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  /* .custom-complete-text {
    padding-top: 30px;
  } */
  
  .complete-percentage-red{
    color: red;  
    font-weight: bolder;
  }
  
  .strong-red{
    color: red;  
    font-weight: bolder;
  }
  
.strong-red a{
  color: red;  
  font-weight: bolder; 
}

  .complete-percentage-green {
    color: green; 
    font-weight: bolder;
  }
  .custom-complete-text {
    display: flex;
    justify-content: flex-end;
    width : 100%;
  }
  .custom-complete-text p {
    display: flex;
    align-items: center;
  }
  
  .custom-complete-text p span.complete-percentage {
    margin-left: 5px; 
  }
  .table {
    background-color: hsla(212, 66%, 59%, 0.63);
  
  }
  .table thead{
    background-color: hsla(0, 0%, 100%, 0.911);
  
  }
  

  .table-category{
    border: 1px solid lightgray;
    text-overflow: initial;
    white-space: nowrap;
    height:35px;
    cursor: pointer;
    vertical-align: middle;
    padding-left: 5px;
    padding-top: 6px;
  }
  .title{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
  }
  .bordered-round-btn {
    border-width: 1px;
    border-radius: 15px;  
    background-color: white; 
    color: #0059A7; 
    padding: 1px 5px;  
  }
  .reqTable TH{
    padding: 2px;
  }
  .reqTable TD{
    padding: 2px;
  }
  
@media (max-width: 768px) {
  .icon-success {
    font-size: 2px; /* Adjust size as needed */
    color: green;    /* Adjust color as needed */

  }
  .container-custom-color-cgfns {
    margin: 0px;
    padding: 3px!important;
  }
  .title{
    font-size:12px;
  }
  .title h3{
    font-size:20px;
  }
  .language-button{
    margin-top: 3px;
    font-size:12px;
  }
  
}

.profile-details img{
  width: 160px;
  height: auto;  
}

  /* step Tab */
  @media (max-width: 1480px) {

    .stepTab li {
      font-size: 80%;
      padding: 6px 10px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.6rem;
    }
    .profile-details img{
      width: 120px;
      height: auto;  
    }
    .profile-details button{
      font-size: xx-small;
      width: 120px;
      padding: 1px;
      background-color: #ffffffa6;
    }
  }
  @media (max-width: 1280px) {

    .stepTab li {
      font-size: 75%;
      padding: 4px 5px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.5rem;
    }
    .profile-details img{
      width: 100px;
      height: auto;  
    }
    
    .app-id-text{
      font-size: 16px;
    }
    
    .profile-details button{
      font-size: x-small;
      width: 100px;
      padding: 0px;
    }
  }
  
  @media (max-width: 1150px) {

    .stepTab li {
      font-size: 60%;
      padding: 4px 5px;
      white-space: nowrap;
    }
    .weltext {
      font-size: 1.3rem;
    }
    .profile-details img{
      width: 80px;
      height: auto;  
    }
    .app-id-text{
      font-size: 14px;
    }
    .profile-details button{
      font-size: xx-small;
      width: 80px;
      padding: 0px;
    }
  }

  /* Secure Payment Portal */
  .payment-button {
    margin-left: 20px;
    padding: 8px 12px;
    background-color: rgb(8, 160, 16); 
    color: #fff !important; 
    border: none;
    cursor: pointer;
  }
  
  .payment-button:disabled {
    background-color: #ccc; 
    color: #999; 
    cursor: not-allowed; 
    opacity: 0.7; 
  }

  .payment-container {
    background-color: white;
    padding: 20px;
    min-height: 100vh;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  
  .instructions h5 {
    text-align: left;
  }
  
  .payment-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
  }
  
  .payment-table th,
  .payment-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .payment-table .price-column {
    text-align: right; 
  }

  .payment-table-noborder{
    border: 0px!important;
    text-align: right!important;
    font-weight: bold!important;
  }
  
  .payment-table .checkbox {
    transform: scale(0.8);
  }
  
  .payment-table .remove-button {
    color: blue;
    cursor: pointer;
    border: none;
    background: none;
  }
  
  .total-label td{
    text-align: right;
    font-weight: bold;
    border: "0px solid #ddd";
    padding: 8px;
  }
  
 .total-amount td{
    text-align: right;
    font-weight: bold;
    border: "0px solid #ddd";
  }
  
  .proceed-cell {
    text-align: center;
  }
  
  .proceed-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .proceed-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
  }
  
  .popup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancel-button,
  .confirm-button {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
  }
  
 
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000; 
    width: 500px; 
    text-align: center;
  }
  
  .popup p {
    margin-bottom: 20px;
  }
  
  .popup-actions {
    display: flex;
    
  }
  
 
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .confirm-button {
    background-color: #4caf50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; 
  }

  .input-with-margin {
    margin-bottom: 10px;
  }
  
  .refund-policy-style {
    display: flex;
    align-items: center;
  }
  
  .custom-checkbox {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .refund-policy-label {
    color: red;
  }
  
  .button-group-margin-top {
    margin-top: 20px;
  }

  /*POPUP CSS*/
.sp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.sp-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
  width: 100%;
  max-width: 1000px;
  max-height: 100vh;
  height: auto;
  overflow: auto;
}

.sp-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.sp-table-header {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 1;
}

.sp-table-cell {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.sp-table-cell-right {
  text-align: right;
}

.sp-table-cell-center {
  text-align: center;
}

.sp-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 8px;
}

.sp-button {
  padding: 8px;
  width: 80px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sp-cancel-button {
  background-color: #f44336;
}

.sp-pay-button {
  background-color: #4caf50;
}

/* Header CSS */

.logout-button {
  background-color: #0c696c;
  color: white;
  border-color: rgb(0, 104, 108);
  transition: background-color 0.3s, color 0.3s;
}

.logout-button.hover {
  background-color: #ffffff;
  color: #0c696c;
}

  
  